import React, { Component, Fragment } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import Authuserorders from '../components/user/Authuserorders'

export class AuthUserOrdersPage extends Component {
  render() {
    return (
      <Fragment>
          <div className='Desktop'>
                    <NavMenuDesktop />
                </div>

                <div className='Mobile'>
                    <NavMenuMobile />
                </div>


                {/* User orders components  */}
                <Authuserorders/>

                <div className='Desktop'>
                    <FooterDesktop />
                </div>
                <div className='Mobile'>
                    <FooterMobile />
                </div>
      </Fragment>
    )
  }
}

export default AuthUserOrdersPage
